import React from 'react';
import RootLayout from '../../layouts/RootLayout';
import HeroSection from '../../sections/Whitepaper/Finance/HeroSection';
import FraudPreventionBanner from '../../sections/Whitepaper/Finance/FraudPreventionBanner';
import MetaTagsProvider from '../../components/MetaTagsProvider';
import { WHITEPAPER_TYPE } from '../../sections/Whitepaper/utils';

function Marketplace() {
    return (
        <RootLayout getStartedCtaLink="https://portal.authenticate.com/register?utm_source=industry&utm_medium=marketplace&utm_campaign=lander&utm_term=navbar">
            <MetaTagsProvider
                title="Authenticate: Marketplace Fraud Prevention & Secure Onboarding"
                description="Discover how modern marketplaces can combat rising fraud while optimizing the onboarding of sellers and buyers. Learn how AI-driven KYC, AML compliance, and continuous monitoring solutions from Authenticate help detect account takeover attempts, meet regulatory requirements, and foster trust and safety across digital platforms."
                keywords="Identity Verification, Fraud Prevention, Seller Screening, Marketplace Identity Verification, KYC Compliance, AML Checks, Risk Management Solutions, ID Verification Service – Ecommerce Identity Verification, KYC software, Online Fraud Detection, Customer onboarding software, Background Checks for Sellers, Marketplace Fraud Prevention"
                path="whitepapers/marketplace"
                ogTitle="Marketplace: Identity Verification & Fraud Prevention Solutions"
                ogDescription="Discover how modern marketplaces can combat rising fraud while optimizing the onboarding of sellers and buyers. Learn how AI-driven KYC, AML compliance, and continuous monitoring solutions from Authenticate help detect account takeover attempts, meet regulatory requirements, and foster trust and safety across digital platforms."
                twitterTitle="Authenticate: Powering Marketplace Trust & Safety"
                twitterDescription="Online marketplace fraud has surged—jeopardizing both sellers and buyers. See how Authenticate’s AI-driven ID checks, AML screening, and global watchlist monitoring help marketplaces streamline onboarding, prevent fraud, and maintain compliance."
                ogImage="https://cdn.authenticating.com/public/website/industries/marketplace/og-image-marketplace-lander.jpg"
            />
            <HeroSection type={WHITEPAPER_TYPE.MARKETPLACE} />
            <FraudPreventionBanner />
        </RootLayout>
    );
}

export default Marketplace;
